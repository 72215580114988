import {rqCreateRequest, rqCreateSearchRequest} from './rqHttpsUtils';
import {
  RQ_APPROVE_KOL_EVENT_API,
  RQ_CREATE_KOL_SCOPE_EVENT_API,
  RQ_GET_EVENT_SPONSOR_DETAILS_BY_ID_API,
  RQ_GET_EVENT_TYPE_API,
  RQ_GET_KOL_EVENT_BY_ID_API,
  RQ_GET_KOL_EVENT_FOR_HOSPOC_SEARCH_API,
  RQ_GET_KOL_EVENT_SEARCH_API,
  RQ_GET_KOL_SCOPES_API,
  RQ_GET_KOL_SCOPES_BY_EVENT_ID_API,
  RQ_GET_KOL_SCOPE_BY_ID_API,
  RQ_GET_KOL_WALLET_BALANCE_API,
  RQ_KOL_SCOPE_FINAL_FT_APPROVE,
  RQ_KOL_SCOPE_SAVE_PAYMENT_API,
  RQ_MARK_ABORT_KOL_EVENT_API,
  RQ_MARK_ABORT_KOL_SCOPE_API,
  RQ_MARK_CONDUCT_KOL_SCOPE_API,
  RQ_MARK_RESCHEDULE_KOL_SCOPE_API,
  RQ_PUT_APPROVE_KOL_SCOPE_API,
  RQ_PUT_KOL_SCOPE_CLOSURE_API,
  RQ_PUT_REJECT_KOL_SCOPE_API,
  RQ_REJECT_KOL_EVENT_API, RQ_RESUBMIT_KOL_EVENT_API, RQ_RESUBMIT_KOL_SCOPE_API, RQ_UPDATE_KOL_ADVANCE_API, RQ_VALIDATE_KOL_SCOPE_API,
} from './constants';
import {useQuery} from '@tanstack/react-query';

export const RQUseGetEventType = (payload, options={}) => {
  return useQuery(
    ['rq_get_event_type', payload],
    async ({queryKey, signal}) => {
      const {eventName, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_EVENT_TYPE_API,
        null,
        token,
        {eventName:eventName},
        signal,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQPostCreateKolScopeEvent = async ({
  kolEvent,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_CREATE_KOL_SCOPE_EVENT_API,
    JSON.stringify({...kolEvent}),
    token,
    null,
    signal,
  );
};

export const RQGetSearchKolEventData = (payload, options ={}) => {
  return useQuery(
    ['rq_get_search_kol_event_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_KOL_EVENT_SEARCH_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQUseGetKolEventByIdData = payload => {
  return useQuery(
    ['rq_get_kol_event_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_KOL_EVENT_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQPutApproveKolEvent = async ({
  approve,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_APPROVE_KOL_EVENT_API,
    JSON.stringify({...approve}),
    token,
    null,
    signal,
  );
};

export const RQPutRejectKolEvent = async ({
  reject,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_REJECT_KOL_EVENT_API,
    JSON.stringify({...reject}),
    token,
    null,
    signal,
  );
};

export const RQPutResubmitKolEvent = async ({
  resubmit,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_RESUBMIT_KOL_EVENT_API,
    JSON.stringify({...resubmit}),
    token,
    null,
    signal,
  );
};

export const RQUseGetWalletBalanceForKolEvent = payload => {
  return useQuery(
    ['rq_get_wallet_balance_kol_event', payload],
    async ({queryKey, signal}) => {
      const {token, params} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_KOL_WALLET_BALANCE_API,
        null,
        token,
        params,
        signal,
      );
    },
    {
      cacheTime: 0,
    },
  );
};

export const RQUpdateKolPaymentInfo = async ({
  eventId,
  details,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_UPDATE_KOL_ADVANCE_API,
    JSON.stringify({...details}),
    token,
    {eventId : eventId},
    signal,
  );
};

export const RQUseGetKolEventCreationSponsorByIdData = (payload, options={}) => {
  return useQuery(
    ['rq_get_kol_event_creation_sponsor_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {kolEventId, token} = queryKey[1];
      return rqCreateRequest(
        RQ_GET_EVENT_SPONSOR_DETAILS_BY_ID_API,
        null,
        token,
        {kolEventId: kolEventId},
        signal,
      );
    });
};

export const RQGetKolScopesByEventId = payload => {
  return useQuery(
    ['rq_get_kol_scopes_by_event_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_KOL_SCOPES_BY_EVENT_ID_API,
        null,
        token,
        {id:id},

        signal,
      );
    },
  );
};

export const RQPutMarkConductScope = async ({
  kolScopeEventId,
  conductedDateTime,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_CONDUCT_KOL_SCOPE_API,
    null,
    token,
    {
      kolScopeEventId: kolScopeEventId,
      conductedDateTime: conductedDateTime,
    },
    signal,
  );
};

export const RQPutMarkRescheduleScope = async ({
  kolScopeEventId,
  rescheduledDateTime,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_RESCHEDULE_KOL_SCOPE_API,
    null,
    token,
    {
      kolScopeEventId: kolScopeEventId,
      rescheduledDateTime: rescheduledDateTime,
    },
    signal,
  );
};

export const RQPutMarkAbortKolScope = async ({
  kolScopeEventId,
  abortedReason,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_ABORT_KOL_SCOPE_API,
    {
      abortedReason: abortedReason,
    },
    token,
    {
      kolScopeEventId: kolScopeEventId,
    },
    signal,
  );
};

export const RQPutMarkAbortKolEvent= async ({
  kolEventId,
  abortedReason,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_MARK_ABORT_KOL_EVENT_API,
    {abortedReason: abortedReason},
    token,
    {
      kolEventId: kolEventId,
    },
    signal,
  );
};

export const RQUseGetKolScopeByIdData = payload => {
  return useQuery(
    ['rq_get_kol_scope_by_id_data', payload],
    async ({queryKey, signal}) => {
      const {id, token} = queryKey[1];

      return rqCreateRequest(
        RQ_GET_KOL_SCOPE_BY_ID_API,
        null,
        token,
        {id:id},
        signal,
      );
    },
  );
};
export const RQGetSearchKolEventDataForHOSPOC = (payload, options = {}) => {
  return useQuery(
    ['rq_get_search_kol_event_data_for_ho_spoc', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_KOL_EVENT_FOR_HOSPOC_SEARCH_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,

    },
  );
};

export const RQPutCloseKOLScope = async ({
  data,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_KOL_SCOPE_CLOSURE_API,
    JSON.stringify({...data}),
    token,
    null,
    signal,
  );
};

export const RQGetSearchKolScopeData = (payload, options ={}) => {
  return useQuery(
    ['rq_get_search_kol_scope_data', payload],
    async ({queryKey, signal}) => {
      const {token, filter} = queryKey[1];
      return rqCreateSearchRequest(
        RQ_GET_KOL_SCOPES_API,
        null,
        token,
        null,
        signal,
        filter,
      );
    },
    {
      cacheTime: 0,
      ...options,
    },
  );
};

export const RQPutApproveKolScope = async ({
  approve,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_APPROVE_KOL_SCOPE_API,
    JSON.stringify({...approve}),
    token,
    null,
    signal,
  );
};

export const RQPutRejectKolScope = async ({
  reject,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_PUT_REJECT_KOL_SCOPE_API,
    JSON.stringify({...reject}),
    token,
    null,
    signal,
  );
};

export const RQPutResubmitKolScope = async ({
  resubmit,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_RESUBMIT_KOL_SCOPE_API,
    JSON.stringify({...resubmit}),
    token,
    null,
    signal,
  );
};

export const RQPutValidateScope = async ({
  scopeId,
  comments,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_VALIDATE_KOL_SCOPE_API,
    {comments: comments},
    token,
    {scopeId: scopeId},
    signal,
  );
};

export const RQFtKolScopeEventApproveReject = async ({
  eventData,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_KOL_SCOPE_FINAL_FT_APPROVE,
    JSON.stringify({...eventData}),
    token,
    null,
    signal,
  );
};

export const RQKolScopePostPayment = async ({
  id,
  payments,
  token,
  signal,
}) => {
  return rqCreateRequest(
    RQ_KOL_SCOPE_SAVE_PAYMENT_API,
    JSON.stringify(payments),
    token,
    {scopeId: id},
    signal,
  );
};
